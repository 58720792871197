//*Components Import
import LibrarySong from "./LibrarySong";
//*Modules Import

const Library = ({
	songs,
	setCurrentSong,
	audioRef,
	isPlaying,
	setSongs,
	libraryStatus,
}) => {
	//*Variables
	//*States
	//*Template
	return (
		<div className={`library ${libraryStatus ? "active-library" : ""}`}>
			<h2>Library</h2>
			<div className='song-library'></div>
			{songs.map((song) => (
				<LibrarySong
					setSongs={setSongs}
					isPlaying={isPlaying}
					audioRef={audioRef}
					songs={songs}
					setCurrentSong={setCurrentSong}
					song={song}
					id={song.id}
					key={song.id}
				/>
			))}
		</div>
	);
};
export default Library;

import { v4 as uuidv4 } from "uuid";
function chillHop() {
	return [
		{
			name: "SebLauz Suck My Ballz",
			cover:
				"https://firebasestorage.googleapis.com/v0/b/seblauz.appspot.com/o/album-cover%2Fseblauz-album-cover.jpg?alt=media&token=8c9ffac3-bbdc-4a2d-ad0a-f043e622bbf6",
			artist: "David Renaud",
			audio:
				"https://firebasestorage.googleapis.com/v0/b/seblauz.appspot.com/o/audio%2FSeblauz_suck_my_ballz.mp3?alt=media&token=4cf438db-239b-40ac-a16d-efd797c375ef",
			color: ["#f16423", "#2ab3bf"],
			id: uuidv4(),
			active: true,
		},
		{
			name: "SebLauz Suck My Ballz",
			cover:
				"https://firebasestorage.googleapis.com/v0/b/seblauz.appspot.com/o/album-cover%2Fseblauz-album-cover.jpg?alt=media&token=8c9ffac3-bbdc-4a2d-ad0a-f043e622bbf6",
			artist: "David Renaud",
			audio:
				"https://firebasestorage.googleapis.com/v0/b/seblauz.appspot.com/o/audio%2FSeblauz_suck_my_ballz2.mp3?alt=media&token=5079be5b-b07f-483c-8492-726fbcb324f3",
			color: ["#f16423", "#2ab3bf"],
			id: uuidv4(),
			active: false,
		},
		{
			name: "SebLauz Suck My Ballz",
			cover:
				"https://firebasestorage.googleapis.com/v0/b/seblauz.appspot.com/o/album-cover%2Fseblauz-album-cover.jpg?alt=media&token=8c9ffac3-bbdc-4a2d-ad0a-f043e622bbf6",
			artist: "David Renaud",
			audio:
				"https://firebasestorage.googleapis.com/v0/b/seblauz.appspot.com/o/audio%2FSeblauz_suck_my_ballz3.mp3?alt=media&token=7bb726b7-4dfc-409f-a0e0-17e470ef9693",
			color: ["#f16423", "#2ab3bf"],
			id: uuidv4(),
			active: false,
		},
		//ADD MORE HERE
	];
}

export default chillHop;

//*Components Import

//*Modules Import

const Song = ({ currentSong }) => {
	//*Variables
	//*States
	//*Template
	return (
		<div className='song-container'>
			<img src={currentSong.cover} alt={currentSong.name} />
			<h2>{currentSong.name}</h2>
			<h3>{currentSong.artist}</h3>
		</div>
	);
};
export default Song;

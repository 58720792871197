import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMusic } from "@fortawesome/free-solid-svg-icons";

const LibNav = ({ setLibraryStatus, libraryStatus }) => {
	return (
		<nav className='lib-nav'>
			<h1>BaBass Player</h1>
			<button onClick={() => setLibraryStatus(!libraryStatus)}>
				Library
				<FontAwesomeIcon icon={faMusic} />
			</button>
		</nav>
	);
};
export default LibNav;
